import { PortableText } from "@portabletext/react";
import { getImageDimensions } from "@sanity/asset-utils";
import { urlForImage } from "@/lib/sanity";
import Image from "next/image";
import Link from "next/link";
import { ExternalLinkSVG } from "@/components/svg";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import Table from "./table";

const ImageComponent = ({ value }) => {
  const { width, height } = getImageDimensions(value);
  return (
    <div className="flex rounded-lg truncate justify-center">
      <Image
        layout="intrinsic"
        width={width}
        height={height}
        alt={value.alt}
        src={urlForImage(value).url()}
      />
    </div>
  );
};

const TableComponent = ({ value }) => {
  return <Table tableRows={value.rows} />;
};

const YouTubeComponent = ({ value }) => {
  const { url } = value;
  const id = getYouTubeId(url);
  const opts = {
    height: "100%",
    width: "100%",
  };
  return (
    <div className="mt-8 youtubeContainer relative w-[100%] h-0 pb-[56.25%] rounded-lg truncate">
      <YouTube
        videoId={id}
        opts={opts}
        containerClassName={"youtubeContainer"}
      />
    </div>
  );
};

export const portableTextPlusComponents = {
  marks: {
    color: ({ children, value }) => {
      const style = { color: value.colorHex };
      return <span style={style}>{children}</span>;
    },
    externalLink: ({ children, ...props }) => {
      return (
        <>
          <a href={props.value.url}>{children}</a>
          <ExternalLinkSVG className="mx-1" />
        </>
      );
    },
    internalLink: ({ children, ...props }) => {
      return (
        <Link href={props.value.url}>
          <a>{children}</a>
        </Link>
      );
    },
    link: ({ children, ...props }) => {
      if (isExternalLink(props.value.href)) {
        return (
          <>
            <a
              className="text-dark-purple hover:text-light-purple no-underline hover:underline"
              target="_blank"
              rel="noopener noreferrer"
              href={props.value.href}
            >
              {children}
              <ExternalLinkSVG className="mx-1" />
            </a>
          </>
        );
      } else {
        return (
          <Link href={props.value.href}>
            <a
              className="text-dark-purple hover:text-light-purple no-underline hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          </Link>
        );
      }
    },
  },
  types: {
    image: ImageComponent,
    youtube: YouTubeComponent,
    table: TableComponent,
  },
};

const isExternalLink = (href) => {
  if (href) {
    return !(href.startsWith("/") || href.includes("justship.sg"));
  }
  return false;
};

export default function PortableTextPlus({ value }) {
  return <PortableText value={value} components={portableTextPlusComponents} />;
}
