export default function Badge(props) {
  return (
    <div className="inline-block text-sm text-dark-purple font-semibold whitespace-nowrap">
      <span
        className="text-white px-3 py-0.5 rounded-md text-xs"
        style={{ backgroundColor: props.colorHex }}
      >
        {props.title}
      </span>
    </div>
  );
}
