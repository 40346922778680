import SegmentContainer from "@/components/segments/segment-container";
import PortableTextPlus from "@/components/portable-text-plus";
import Badge from "@/components/badge";
import { getIconImage } from "@/lib/utils";
import classNames from "classnames";

export const getLineStyles = (segment) => {
  return {
    borderStyle: segment.lineStyle || "solid",
    borderColor: segment.lineColor?.color.hex || "#6211CB",
  };
};

export const getLineDotStyles = (segment) => {
  return {
    backgroundColor: segment.lineColor?.color.hex || "#6211CB",
  };
};

export default function VerticalSteps({ segment, ...props }) {
  return (
    <SegmentContainer
      className={props.paddingClasses}
      style={{ ...props.bgImageStyle, ...props.bgColorStyle }}
    >
      <div className={`pl-5 ${props.widthClasses}`}>
        {segment.heading && (
          <div className="mb-8 lg:mb-12 leading-snug font-semibold text-xl md:text-3xl">
            <PortableTextPlus value={segment.heading} />
          </div>
        )}
        <ol className="flex flex-col">
          {segment.blocks.map((block, index) => {
            const iconImage = block.icon ? getIconImage(block.icon) : null;
            return (
              <li className="relative" key={block._key}>
                <div
                  className={classNames(
                    "pl-6",
                    index == segment.blocks.length - 1 ? "" : "border-l-2"
                  )}
                  style={getLineStyles(segment)}
                >
                  <div
                    className="absolute inset-0 -left-[7px] w-[16px] h-[16px] rounded-full"
                    style={getLineDotStyles(segment)}
                  ></div>
                  <div className="flex whitespace-pre-line gap-x-1">
                    {iconImage && <div className="w-[20px]">{iconImage}</div>}
                    <div className="-mt-1 ml-2 pb-10">
                      <div
                        className={classNames(
                          "leading-snug font-semibold",
                          props.headingSizeClasses[block.headingSize] ||
                            "text-lg"
                        )}
                      >
                        <PortableTextPlus value={block.heading} />
                      </div>
                      <div className="mt-4 leading-snug prose">
                        <PortableTextPlus value={block.content} />
                        {block.badges && (
                          <div className="space-x-2">
                            {block.badges.map((badge) => (
                              <Badge key={badge.title} {...badge} />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </SegmentContainer>
  );
}
