import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

export default function Table({ tableRows }) {
  return (
    <div
      className={classNames(
        "overflow-x-auto max-w-xs sm:max-w-6xl m-auto mb-8 md:mb-16"
      )}
    >
      <table className="border-collapse border-y border-y-light-purple text-sm md:text-base w-full">
        <thead className="text-left align-top">
          <tr>{generateRow(tableRows[0].cells, true)}</tr>
        </thead>
        <tbody>
          {tableRows.slice(1).map((rows, index) => {
            const { cells } = rows;
            return (
              <tr
                key={uuidv4()}
                className="border-y border-y-light-purple text-xs md:text-base"
              >
                {generateRow(cells)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const generateRow = (cells, isHeader = false) => {
  if (isHeader) {
    return cells.map((cell) => (
      <th className="py-2 px-2 md:px-4" key={uuidv4()}>
        <p className="my-1">{cell}</p>
      </th>
    ));
  } else
    return cells.map((cell) => (
      <td className="py-2 px-2 md:px-4" key={uuidv4()}>
        <p className="max-w-max pr-2 md:pr-0 my-1">{cell}</p>
      </td>
    ));
};
